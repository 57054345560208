.feature{
    height: 320px;
 
    padding: 6px;
    padding-top: 12px;
    padding-bottom: auto;
}
.feature_logo{
    width: 300px !important;
}

.img-fluid{
    width: 353px;
}
.feature_single {
  opacity: 0;
  transform: translateY(20px); /* Initially, move features 20px down */
  transition: opacity 0.5s ease, transform 0.5s ease; /* Smooth transition */
}
.feature_single.visible {
  opacity: 1;
  transform: translateY(0); /* Move features back to their original position */
}
.home{
    /* add smooth scroll */
    scroll-behavior: smooth;
    


}
.feature_single {
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.5s ease, transform 0.5s ease;
}

.timeline_single.show {
  opacity: 1;
  transform: translateY(0);
}
.show{
  opacity: 1;
  transform: translateY(0);

}

.feature img{
  width: 58px;
}